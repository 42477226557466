/*------------------------------------*\
    $BUTTONS
\*------------------------------------*/
.btn {
    padding: 1em 2em;
    border: 2px solid currentColor;
    transition: all .3s ease-in-out, color .3s ease-in-out;
    color: inherit;
    border-radius: var(--border-radius-2);
}

.btn:hover,
.btn:focus,
.btn:active {
    color: var(--black);
    background-color: rgba(255,255,255,.75);
}

/*------------------------------------*\
    $BUTTON SMALL
\*------------------------------------*/
.btn--small {
    padding: .25em .75em;
}

/*------------------------------------*\
    $BUTTON 2
\*------------------------------------*/
.btn--2 {
    background-color: var(--color-6);
    border: 1px solid transparent;
    padding: 0 1em;
}

.btn--2:hover,
.btn--2:focus,
.btn--2:active {
    color: inherit;
    background-color: transparent;
    border-color: currentColor;
}

/*------------------------------------*\
    $BUTTON GHOST
\*------------------------------------*/
.btn--ghost {
    background-color: transparent;
    border-color: currentColor;
    color: inherit;
}

.btn--ghost:hover,
.btn--ghost:focus,
.btn--ghost:active {
    background-color: var(--color-9);
    border-color: var(--color-9);
    color: var(--white);
}
/*------------------------------------*\
    $BUTTONS ALT
\*------------------------------------*/
.btn--alt {
    background-color: var(--color-5);
    border-color: var(--color-5);
    color: var(--white);
    padding: .5em 1em;
}

.btn--alt:hover,
.btn--alt:focus,
.btn--alt:active {
    background-color: transparent;
    color: var(--color-5);
}

/*------------------------------------*\
    $BUTTONS ALT 2
\*------------------------------------*/
.btn--alt-2 {
    background-color: var(--color-7);
    border-color: var(--color-7);
    color: var(--color-8);
    padding: .5em 1em;
}

.btn--alt-2:hover,
.btn--alt-2:focus,
.btn--alt-2:active {
    background-color: transparent;
    color: var(--color-8);
}