/*------------------------------------*\
    $COLORS
\*------------------------------------*/

:root {
    --white: #fff;
    --black: #000;

    --color-1: #FFF100;
    --color-2: #2E2E2E;
    --color-3: #F2F2F2;

    --color-4: #624096;
    --color-5: #4da1ff;
    --color-6: #321C8A;

    --color-7: #f4f7f9;
    --color-8: #989898;
    --color-9: #323c47;

    --color-10: #497EB9;
    --color-11: #27CDDB;

    /* gradients */
    --gradient-1a: #242659;
    --gradient-1b: #aee7e7;

    --gradient-2a: #25184f;
    --gradient-2b: #e15050;

    --gradient-3a: #a31740;
    --gradient-3b: #fff218;

    --gradient-4a: #c8176f;
    --gradient-4b: #88f9fd;

    --gradient-5a: #974881;
    --gradient-5b: #fec827;
}



/*------------------------------------*\
    $LAYOUT
\*------------------------------------*/

:root {
  --gutter: 3rem;
  --gutter-p: calc(var(--gutter) * .25);

  --max-width: 198rem;
  --max-width-1: var(--max-width);
  --max-width-2: 144rem;
  --max-width-3: 100rem;
}



/*------------------------------------*\
    $TYPOGRAPHY
\*------------------------------------*/

:root {
    --font-family: 'Titillium Web', sans-serif;
    --font-family-2: 'Titillium Web', sans-serif;
}



/*------------------------------------*\
    $FONT SIZES
\*------------------------------------*/
:root {
    /* MODULAR SCALE */
    --font-size-base: 1.8rem;
    --font-ratio: 1.067;
    
    /* FONT SIZES */
    --font-size-h7: 1em;
    /* smaller font size */
    --font-size-h8: calc( var(--font-size-h7) / var(--font-ratio) );
    --font-size-h9: calc( var(--font-size-h8) / var(--font-ratio) );
    /* bigger font size */
    --font-size-h6: calc( var(--font-size-h7) * var(--font-ratio) );
    --font-size-h5: calc( var(--font-size-h6) * var(--font-ratio) );
    --font-size-h4: calc( var(--font-size-h5) * var(--font-ratio) );
    --font-size-h3: calc( var(--font-size-h4) * var(--font-ratio) );
    --font-size-h2: calc( var(--font-size-h3) * var(--font-ratio) );
    --font-size-h1: calc( var(--font-size-h2) * var(--font-ratio) );
    --font-size-h0: calc( var(--font-size-h1) * var(--font-ratio) );

    --line-height-h0: 1.25;
    --line-height-h1: 1.25;
    --line-height-h2: 1.25;
    --line-height-h3: 1.25;
    --line-height-h4: 1.25;
    --line-height-h5: 1.25;
    --line-height-h6: 1.25;
    --line-height-h7: inherit;
    --line-height-h8: 1.25;
    --line-height-h9: 1.25;
}

@media all and (min-width: 768px) {
    :root {
        /*--font-size-base: 2rem;*/
        --font-ratio: 1.2;
    }
}



/*------------------------------------*\
    $FONT EFFECTS
\*------------------------------------*/

:root {
    --border-radius: 1em;
    --border-radius-2: .5em;
}


:root {
    --text-shadow: none;

    --letter-spacing: .035em;
    --letter-spacing-2: -.015em;
}
