html {
    font-size: 62.5%;
    /*font-display: auto;*/
    /*font-display: block;*/
    /*font-display: swap;*/
    /*font-display: fallback;*/
    /*font-display: optional;*/
/*    font-display: swap;*/

    scroll-behavior: smooth;
    overflow-wrap: break-word;
}

body {
    position: relative;
    text-rendering: optimizeLegibility;
}


body a {
  transition: color .3s ease-in-out;
}

/*------------------------------------*\
    $BTN RESET
\*------------------------------------*/

.btn {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;

    display: inline-block;
    text-decoration: none;
    cursor: pointer;
}



/*------------------------------------*\
    $FONT
\*------------------------------------*/

body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
    margin: 0;
    padding: 0;
    font-weight: inherit;
}

/**
 * inherit all the style from headings if it has a link
 */
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
    display: block;
    text-decoration: inherit;
    font-style: inherit;
    font-weight: inherit;
    color: inherit;
}

ul, ol, dl {
    margin: 0;
    padding: 0;
}

/*------------------------------------*\
    $NAV
\*------------------------------------*/

.nav,
.nav ul,
/*ul*/.nav,
.nav ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

    /*li*/.nav__item,
    /*li*/.nav li {
        white-space: nowrap;
    }

    /*a*/.nav__link {
        display: block;
        color: inherit;
        text-decoration: none;
    }



/*------------------------------------*\
    $IMG CLASSES
\*------------------------------------*/

img {
    max-width: 100%;
    height: auto;
}

/*img*/.pixelated {
    image-rendering: -webkit-optimize-contrast;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: pixelated;
    -ms-interpolation-mode: nearest-neighbor;
}

/*img*/.img-fullwidth {
    max-width: none;
    width: 100%;
}



/*------------------------------------*\
    $IMG COVER
\*------------------------------------*/

@supports (object-fit: contain) {
    .img-contain {
        width: 100%;
        height: 100%;
        object-fit: contain;
        max-width: none;
    }
}

@supports (object-fit: cover) {
    /* default: middle center */
    .img-cover {
        width: 100% !important;
        height: 100% !important;
        object-fit: cover;
        max-width: none;
    }



    /* top */
    .img-cover--top-left {
        object-position: 25% 25%;
    }

    .img-cover--top,
    .img-cover--top-center {
        object-position: 50% 25%;
    }

    .img-cover--top-right {
        object-position: 75% 25%;
    }

    /* middle */
    .img-cover--left,
    .img-cover--middle-left {
        object-position: 25% 50%;
    }

    .img-cover--right,
    .img-cover--middle-right {
        object-position: 75% 50%;
    }

    /* bottom */
    .img-cover--bottom-left {
        object-position: 25% 75%;
    }

    .img-cover--bottom,
    .img-cover--bottom-center {
        object-position: 50% 75%;
    }

    .img-cover--bottom-right {
        object-position: 75% 75%;
    }



    /* top edge */
    .img-cover--top-left-max {
        object-position: 0% 0%;
    }

    .img-cover--top-max,
    .img-cover--top-center-max {
        object-position: 50% 0%;
    }

    .img-cover--top-right-max {
        object-position: 100% 0%;
    }

    /* middle edge*/
    .img-cover--left-max,
    .img-cover--middle-left-max {
        object-position: 0% 50%;
    }

    .img-cover--right-max,
    .img-cover--middle-right-max {
        object-position: 100% 50%;
    }

    /* bottom edge */
    .img-cover--bottom-left-max {
        object-position: 0% 100%;
    }

    .img-cover--bottom-max,
    .img-cover--bottom-center-max {
        object-position: 50% 100%;
    }

    .img-cover--bottom-right-max {
        object-position: 100% 100%;
    }
}

/*------------------------------------*\
    $IMG ABSOLUTE
\*------------------------------------*/

.img-absolute {
    position: relative;
    overflow: hidden;
}

.img-absolute > img {
    position: absolute;
    top: 50%;
    left: 50%;
    min-height: 101%;
    min-width: 101%;
    transform: translate(-50%, -50%);
    max-width: none;
}

.img-absolute--max-width > img {
    max-width: 100%;
}

/* top */
.img-absolute--top-left > img {
    top: 25%;
    left: 25%;
    transform: translate(-25%, -25%);
}

.img-absolute--top > img {
    top: 25%;
    transform: translate(-50%, -25%);
}

.img-absolute--top-right > img {
    top: 25%;
    left: 75%;
    transform: translate(-75%, -25%);
}

.img-absolute--top-right-max > img {
    top: 0%;
    left: 100%;
    transform: translate(-100%, -0%);
}

/* middle */
.img-absolute--left > img {
    left: 25%;
    transform: translate(-25%, -50%);
}

.img-absolute--right > img {
    left: 75%;
    transform: translate(-75%, -50%);
}

/* bottom */
.img-absolute--bottom-left > img {
    top: 75%;
    left: 25%;
    transform: translate(-25%, -75%);
}

.img-absolute--bottom > img {
    top: 75%;
    transform: translate(-50%, -75%);
}

.img-absolute--bottom-right > img {
    top: 75%;
    left: 75%;
    transform: translate(-75%, -75%);
}





/*------------------------------------*\
    $GLOBAL CLASSES
\*------------------------------------*/

.hidden {
    display: none !important;
    visibility: hidden !important;
}





/*------------------------------------*\
    $TEXT TRANSFORM
\*------------------------------------*/

.uppercase {
    text-transform: uppercase !important;
}

.lowercase {
    text-transform: lowercase;
}





/*------------------------------------*\
    $TEXT ALIGN
\*------------------------------------*/

.text-center {
    text-align: center !important;
}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.text-justify {
    text-align: justify !important;
}





/*------------------------------------*\
    $FONT WEIGHT
\*------------------------------------*/

.thin {
    font-weight: 100 !important;
}

/* also thin */
.extra-light,
.ultra-light,
.extralight,
.ultralight {
    font-weight: 200 !important;
}

/* book */
.light {
    font-weight: 300 !important;
}

/* or regular */
.normal,
.regular {
    font-weight: 400 !important;
}

.medium {
    font-weight: 500 !important;
}

/* also demibold */
.semi-bold,
.demi-bold,
.semibold, 
.demibold {
    font-weight: 600 !important;
}

.bold {
    font-weight: 700 !important;
}

.extra-bold,
.ultra-bold {
    font-weight: 800 !important;
}

/* also black */
.heavy,
.black {
    font-weight: 900 !important;
}



/*------------------------------------*\
    $DISPLAY
\*------------------------------------*/
.display--inline-block {
    display: inline-block;
}

/*------------------------------------*\
    $QUOTE
\*------------------------------------*/

.pullquote p:first-of-type:before {
    font-family: Georgia,serif !important;
    content: open-quote;
}

.pullquote p:first-of-type:after {
    font-family: Georgia,serif !important;
    content: close-quote;
}

.pullquote p {
    quotes: "\201C" "\201D";
}




