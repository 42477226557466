/*------------------------------------*\
  GLOBAL CLASS
\*------------------------------------*/
.lc {
  margin: 0 auto;
  /*width: 100%;*/
  box-sizing: content-box;
}

.lc--left {
  margin-left: 0;
}

.lc--right {
  margin-right: 0;
}

.lc--1 {
  max-width: var(--max-width);
}

.lc--2 {
  max-width: var(--max-width-2);
}

.lc--3 {
  max-width: var(--max-width-3);
}

/*------------------------------------*\
  BODY LAYOUT
\*------------------------------------*/

.body {
  display: grid;
  min-height: 100vh;
  
  grid-template-areas: 
    "header"
    "main"
    "footer"
  ;
}

.header {
  z-index: 10000;
  grid-area: header;
}

.main {
  grid-area: main;
}

.footer {
  grid-area: footer;

}

/*------------------------------------*\
  BODY TOPBAR
\*------------------------------------*/
/* sticky footer */
.body--topbar {
  grid-template-rows: auto 1fr;
}

.body--topbar .nav {
  display: flex;
  flex-wrap: wrap;
}

.body--topbar .header__content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.body--topbar .header__nav {
  display: flex;
  align-items: center;
}

/* nav */

.body--topbar .nav ul {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
}

.body--topbar .nav__item {
  position: relative;
}

.body--topbar .nav .nav__item:hover > ul {
  display: block;
}

/*------------------------------------*\
  BODY SIDEBAR
\*------------------------------------*/
.body--sidebar {
  grid-template-areas: 
    "header main"
    "header footer"
  ;
  grid-template-rows: 1fr auto;
  grid-template-columns: auto 1fr;
}

.body--sidebar .header__content {
  display: grid;
  grid-template-rows: 1fr auto 1fr;
  grid-gap: var(--gutter);
  height: 100%;
}

.body--sidebar .header__nav {
  display: contents;
}

.header__social {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

/*------------------------------------*\
  BODY TOPBAR : JUSTIFY
\*------------------------------------*/
.body--topbar-justify .header__content {
  display: grid;
  grid-gap: var(--gutter);
  grid-template-columns: auto 1fr auto;
} 

.body--topbar-justify .header__nav {
  display: contents;
}

.body--topbar-justify .header__nav .navigation {
  display: flex;
  justify-content: center;
}

/*------------------------------------*\
  BODY TOPBAR : CENTER
\*------------------------------------*/
.body--topbar-center .header__content {
  flex-direction: column;
}

/*------------------------------------*\
  BODY TOPBAR : ABSOLUTE
\*------------------------------------*/
.body--topbar-absolute {
  display: grid;
  grid-template-rows: auto 1fr auto;
}

.body--topbar-absolute .header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10000;
}

.body--topbar-absolute .header__content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

/*------------------------------------*\
  HEADER GUTTER
\*------------------------------------*/
.header__container {
  display: flow-root;
  height: 100%;
}

.header__item {
  margin-left: var(--gutter-header);
  margin-bottom: var(--gutter-header);
}

/*------------------------------------*\
   NAV GUTTER
\*------------------------------------*/
.navigation {
    display: flow-root;
}

.nav {
  margin-left: calc(var(--gutter-nav) * -1);
  margin-bottom: calc(var(--gutter-nav) * -1);
}

.nav__item {
  margin-left: var(--gutter-nav);
  margin-bottom: var(--gutter-nav);
}


/*------------------------------------*\
   GRID
\*------------------------------------*/

.grid {
  display: grid;
}

/*------------------------------------*\
   GRID Gap
\*------------------------------------*/

.grid--gap-1px {
    grid-gap: 1px;
}

.grid--gap-2px {
    grid-gap: 2px;
}

.grid--gap-3px {
    grid-gap: 3px;
}

.grid--gap-5px {
    grid-gap: 5px;
}

.grid--gap-quarter {
    grid-gap: calc(var(--gutter) * .25);
}

.grid--gap-half,
.grid--narrow {
    grid-gap: calc(var(--gutter) * .5);
}

.grid--gap,
.grid--gap-default {
    grid-gap: calc(var(--gutter) * 1);
}

@media all and (min-width: 768px) {

}
@media all and (min-width: 1024px) {
  
}

.grid--gap-and-half {
    grid-gap: calc(var(--gutter) * 1.5);
}

.grid--gap-double,
.grid--wide {
    grid-gap: calc(var(--gutter) * 2);
}

@media all and (min-width: 768px) {
  .grid--gap-double-tablett {
      grid-gap: calc(var(--gutter) * 2);
  }
}
@media all and (min-width: 1024px) {
  .grid--gap-double-desktop {
      grid-gap: calc(var(--gutter) * 2);
  }
}

.grid--gap-tripple {
    grid-gap: calc(var(--gutter) * 3);
}

@media all and (min-width: 768px) {
  .grid--gap-tripple-tablett {
      grid-gap: calc(var(--gutter) * 3);
  }
}
@media all and (min-width: 1024px) {
  .grid--gap-tripple-desktop {
      grid-gap: calc(var(--gutter) * 3);
  }
}

/*------------------------------------*\
   GRID GAP ROW
\*------------------------------------*/

.grid--row-gap {
  grid-gap: var(--gutter);
}

.grid--row-gap-1px {
    grid-row-gap: 1px;
}

.grid--row-gap-2px {
    grid-row-gap: 2px;
}

.grid--row-gap-3px {
    grid-row-gap: 3px;
}

.grid--row-gap-5px {
    grid-row-gap: 5px;
}

.grid--row-gap-quarter {
    grid-row-gap: calc(var(--gutter) * .25);
}

.grid--row-gap-half {
    grid-row-gap: calc(var(--gutter) * .5);
}

.grid--row-gap,
.grid--row-gap-default {
    grid-row-gap: calc(var(--gutter) * 1);
}

.grid--row-gap-double {
    grid-row-gap: calc(var(--gutter) * 2);
}

.grid--row-gap-tripple {
    grid-row-gap: calc(var(--gutter) * 3);
}


/*------------------------------------*\
   GRID GAP COLUMN
\*------------------------------------*/

.grid--column-gap {
  grid-column-gap: var(--gutter);
}

.grid--column-gap-1px {
    grid-column-gap: 1px;
}

.grid--column-gap-2px {
    grid-column-gap: 2px;
}

.grid--column-gap-3px {
    grid-column-gap: 3px;
}

.grid--column-gap-5px {
    grid-column-gap: 5px;
}

.grid--column-gap-quarter {
    grid-column-gap: calc(var(--gutter) * .25);
}

.grid--column-gap-half {
    grid-column-gap: calc(var(--gutter) * .5);
}

.grid--column-gap,
.grid--column-gap-default {
    grid-column-gap: calc(var(--gutter) * 1);
}

.grid--column-gap-double {
    grid-column-gap: calc(var(--gutter) * 2);
}

.grid--column-gap-tripple {
    grid-column-gap: calc(var(--gutter) * 3);
}

/*------------------------------------*\
   GRID Alignment
\*------------------------------------*/

/* vertical alignment */
.grid--top {
  align-items: start;
}

.grid--middle {
  align-items: center;
}

.grid--bottom {
  align-items: end;
}

/* horizontal */
.grid--left {
  justify-items: start;
}

.grid--center {
  justify-items: center;
}

.grid--right {
  justify-items: end;
}

/*------------------------------------*\
   GRID Direction
\*------------------------------------*/
/* direction */
.grid--reverse {
  direction: rtl;
}

.grid--reverse > * {
  direction: ltr;
}

.grid--reverse.grid--left {
  justify-items: end;
}

.grid--reverse.grid--right {
  justify-items: start;
}

/*------------------------------------*\
   GRID Columns mobile
\*------------------------------------*/

@media all and (max-width: 767px) {
  .grid--2-mobile {
    grid-template-columns: repeat(2, 1fr);
  }

  .grid--3-mobile {
    grid-template-columns: repeat(3, 1fr);
  }

  .grid--4-mobile {
    grid-template-columns: repeat(4, 1fr);
  }

  .grid--5-mobile {
    grid-template-columns: repeat(5, 1fr);
  }

  .grid--6-mobile {
    grid-template-columns: repeat(6, 1fr);
  }

  .grid--7-mobile {
    grid-template-columns: repeat(7, 1fr);
  }

  .grid--8-mobile {
    grid-template-columns: repeat(8, 1fr);
  }

  .grid--9-mobile {
    grid-template-columns: repeat(9, 1fr);
  }

  .grid--10-mobile {
    grid-template-columns: repeat(10, 1fr);
  }

  .grid--11-mobile {
    grid-template-columns: repeat(11, 1fr);
  }

  .grid--12-mobile {
    grid-template-columns: repeat(12, 1fr);
  }
}


/*------------------------------------*\
   GRID Columns mobile
\*------------------------------------*/

@media all and (min-width: 768px) {
  .grid--2-tablett {
    grid-template-columns: repeat(2, 1fr);
  }

  .grid--3-tablett {
    grid-template-columns: repeat(3, 1fr);
  }

  .grid--4-tablett {
    grid-template-columns: repeat(4, 1fr);
  }

  .grid--5-tablett {
    grid-template-columns: repeat(5, 1fr);
  }

  .grid--6-tablett {
    grid-template-columns: repeat(6, 1fr);
  }

  .grid--7-tablett {
    grid-template-columns: repeat(7, 1fr);
  }

  .grid--8-tablett {
    grid-template-columns: repeat(8, 1fr);
  }

  .grid--9-tablett {
    grid-template-columns: repeat(9, 1fr);
  }

  .grid--10-tablett {
    grid-template-columns: repeat(10, 1fr);
  }

  .grid--11-tablett {
    grid-template-columns: repeat(11, 1fr);
  }

  .grid--12-tablett {
    grid-template-columns: repeat(12, 1fr);
  }
}


/*------------------------------------*\
   GRID Columns desktop
\*------------------------------------*/

@media all and (min-width: 1024px) {
  .grid--2-desktop {
    grid-template-columns: repeat(2, 1fr);
  }

  .grid--3-desktop {
    grid-template-columns: repeat(3, 1fr);
  }

  .grid--4-desktop {
    grid-template-columns: repeat(4, 1fr);
  }

  .grid--5-desktop {
    grid-template-columns: repeat(5, 1fr);
  }

  .grid--6-desktop {
    grid-template-columns: repeat(6, 1fr);
  }

  .grid--7-desktop {
    grid-template-columns: repeat(7, 1fr);
  }

  .grid--8-desktop {
    grid-template-columns: repeat(8, 1fr);
  }

  .grid--9-desktop {
    grid-template-columns: repeat(9, 1fr);
  }

  .grid--10-desktop {
    grid-template-columns: repeat(10, 1fr);
  }

  .grid--11-desktop {
    grid-template-columns: repeat(11, 1fr);
  }

  .grid--12-desktop {
    grid-template-columns: repeat(12, 1fr);
  }
}

/*------------------------------------*\
   GRID Columns
\*------------------------------------*/

.grid--base {
  grid-template-columns: repeat(12, 1fr);
}

.grid--2 {
  grid-template-columns: repeat(2, 1fr);
}

.grid--3 {
  grid-template-columns: repeat(3, 1fr);
}

.grid--4 {
  grid-template-columns: repeat(4, 1fr);
}

.grid--5 {
  grid-template-columns: repeat(5, 1fr);
}

.grid--6 {
  grid-template-columns: repeat(6, 1fr);
}

.grid--7 {
  grid-template-columns: repeat(7, 1fr);
}

.grid--8 {
  grid-template-columns: repeat(8, 1fr);
}

.grid--9 {
  grid-template-columns: repeat(9, 1fr);
}

.grid--10 {
  grid-template-columns: repeat(10, 1fr);
}

.grid--11 {
  grid-template-columns: repeat(11, 1fr);
}

.grid--12 {
  grid-template-columns: repeat(12, 1fr);
}


/*------------------------------------*\
   GRID Item mobile
\*------------------------------------*/

@media all and (max-width: 767px) {
  .grid__item--2-mobile {
    grid-column: span 2;
  }

  .grid__item--3-mobile {
    grid-column: span 3;
  }

  .grid__item--4-mobile {
    grid-column: span 4;
  }

  .grid__item--5-mobile {
    grid-column: span 5;
  }

  .grid__item--6-mobile {
    grid-column: span 6;
  }

  .grid__item--7-mobile {
    grid-column: span 7;
  }

  .grid__item--8-mobile {
    grid-column: span 8;
  }

  .grid__item--9-mobile {
    grid-column: span 9;
  }

  .grid__item--10-mobile {
    grid-column: span 10;
  }

  .grid__item--11-mobile {
    grid-column: span 11;
  }
}

/*------------------------------------*\
   GRID Item Tablet
\*------------------------------------*/

@media all and (min-width: 768px) {
  .grid__item--2-tablett {
    grid-column: span 2;
  }

  .grid__item--3-tablett {
    grid-column: span 3;
  }

  .grid__item--4-tablett {
    grid-column: span 4;
  }

  .grid__item--5-tablett {
    grid-column: span 5;
  }

  .grid__item--6-tablett {
    grid-column: span 6;
  }

  .grid__item--7-tablett {
    grid-column: span 7;
  }

  .grid__item--8-tablett {
    grid-column: span 8;
  }

  .grid__item--9-tablett {
    grid-column: span 9;
  }

  .grid__item--10-tablett {
    grid-column: span 10;
  }

  .grid__item--11-tablett {
    grid-column: span 11;
  }
}

/*------------------------------------*\
   GRID Item Desktop
\*------------------------------------*/

@media all and (min-width: 1024px) {
  .grid__item--2-desktop {
    grid-column: span 2;
  }

  .grid__item--3-desktop {
    grid-column: span 3;
  }

  .grid__item--4-desktop {
    grid-column: span 4;
  }

  .grid__item--5-desktop {
    grid-column: span 5;
  }

  .grid__item--6-desktop {
    grid-column: span 6;
  }

  .grid__item--7-desktop {
    grid-column: span 7;
  }

  .grid__item--8-desktop {
    grid-column: span 8;
  }

  .grid__item--9-desktop {
    grid-column: span 9;
  }

  .grid__item--10-desktop {
    grid-column: span 10;
  }

  .grid__item--11-desktop {
    grid-column: span 11;
  }
}

/*------------------------------------*\
   GRID Item
\*------------------------------------*/
.grid__item--2 {
  grid-column: span 2;
}

.grid__item--3 {
  grid-column: span 3;
}

.grid__item--4 {
  grid-column: span 4;
}

.grid__item--5 {
  grid-column: span 5;
}

.grid__item--6 {
  grid-column: span 6;
}

.grid__item--7 {
  grid-column: span 7;
}

.grid__item--8 {
  grid-column: span 8;
}

.grid__item--9 {
  grid-column: span 9;
}

.grid__item--10 {
  grid-column: span 10;
}

.grid__item--11 {
  grid-column: span 11;
}
/*------------------------------------*\
   GRID Overlap
\*------------------------------------*/

.grid--overlap {
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}

.grid--overlap > * {
  grid-column: -1 / 1;
  grid-row: -1 / 1;

  position: relative;
}


/*------------------------------------*\
   GRID Proportion
\*------------------------------------*/

.grid--1-11 {
  grid-template-columns: 1fr 11fr;
}

.grid--2-10 {
  grid-template-columns: 2fr 10fr;
}

.grid--3-9 {
  grid-template-columns: 3fr 9fr;
}

.grid--4-8 {
  grid-template-columns: 4fr 8fr;
}

@media all and (min-width: 768px) {
  .grid--4-8-tablett {
    grid-template-columns: 4fr 8fr;
  }
}

@media all and (min-width: 1024px) {
  .grid--4-8-desktop {
    grid-template-columns: 4fr 8fr;
  }
}

.grid--5-7 {
  grid-template-columns: 5fr 7fr;
}

.grid--6-6 {
  grid-template-columns: 6fr 6fr;
}

.grid--7-5 {
  grid-template-columns: 7fr 5fr;
}

.grid--8-4 {
  grid-template-columns: 8fr 4fr;
}

@media all and (min-width: 768px) {
  .grid--8-4-tablett {
    grid-template-columns: 8fr 4fr;
  }
}

@media all and (min-width: 1024px) {
  .grid--8-4-desktop {
    grid-template-columns: 8fr 4fr;
  }
}

.grid--9-3 {
  grid-template-columns: 9fr 3fr;
}

.grid--10-2 {
  grid-template-columns: 10fr 2fr;
}

.grid--11-1 {
  grid-template-columns: 11fr 1fr;
}

/*------------------------------------*\
   GRID Presets
\*------------------------------------*/

.grid--1-3 {
  grid-template-columns: 1fr 3fr;
}

.grid--1-2 {
  grid-template-columns: 1fr 2fr;
}

.grid--2-1-1 {
  grid-template-columns: 2fr 1fr 1fr;
}

.grid--auto-1fr {
  grid-template-columns: auto 1fr;
}





/*------------------------------------*\
   GRID Metro
\*------------------------------------*/

.grid--metro {
    grid-auto-flow: dense;
    grid-template-columns: repeat(2, 1fr);
}

@media all and (min-width: 768px) {
    .grid--metro {
        grid-template-columns: repeat(4, 1fr);
    }

    .grid--metro > *:nth-child(3n+1) {
      grid-column: span 2;
      grid-row: span 2;
    }
}


/*------------------------------------*\
   GRID Metro 2
\*------------------------------------*/

.grid--metro-2 {
    grid-auto-flow: dense;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
}

.grid--metro-2 > *:nth-child(6n+1) {
    grid-row: span 2;
}

@media all and (min-width: 768px) {
  .grid--metro-2-tablett {
      display: grid;
      grid-auto-flow: dense;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr;
  }

  .grid--metro-2-tablett > *:nth-child(6n+1) {
      grid-row: span 2;
  }
}

@media all and (min-width: 1024px) {
  .grid--metro-2-desktop {
      display: grid;
      grid-auto-flow: dense;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr;
  }

  .grid--metro-2-desktop > *:nth-child(6n+1) {
      grid-row: span 2;
  }
}


/*------------------------------------*\
   GRID Metro 3
\*------------------------------------*/

.grid--metro-3 {
    grid-auto-flow: dense;
    grid-template-columns: repeat(2, 1fr);
}

@media all and (min-width: 768px) {
    .grid--metro-3 {
        grid-template-columns: repeat(4, 1fr);
    }

    .grid--metro-3 > *:nth-child(6n+1) {
      grid-column: span 2;
      grid-row: span 2;
    }
}



/*------------------------------------*\
    # Height
\*------------------------------------*/
.height--viewport {
    min-height: 100vh;
}

.height--100 {
    min-height: 100%;
}

.height--100-all,
.height--100-all * {
    height: 100%;
}

.height--three-quarters {
    min-height: calc(100vh * 3/4);
}

.height--two-thirds {
    min-height: calc(100vh * 2/3);
}

.height--one-half {
    min-height: calc(100vh * 1/2);
}

.height--one-third {
    min-height: calc(100vh * 1/3);
}

.height--one-quarter {
    min-height: calc(100vh * 1/4);
}


/*------------------------------------*\
  PADDING
\*------------------------------------*/
.padding--quarduple {
  padding: calc( var(--gutter) * 4 );
}

.padding--tripple {
  padding: calc( var(--gutter) * 3 );
}

.padding--double {
  padding: calc( var(--gutter) * 2 );
}

.padding--and-half {
  padding: calc( var(--gutter) * 1.5 );
}

.padding--and-third {
  padding: calc( var(--gutter) * 1.333 );
}

.padding--and-quarter {
  padding: calc( var(--gutter) * 1.25 );
}

.padding {
  padding: calc( var(--gutter) * 1 );
}

.padding--half {
  padding: calc( var(--gutter) * .5 );
}

.padding--third {
  padding: calc( var(--gutter) * .333 );
}

.padding--quarter {
  padding: calc( var(--gutter) * .25 );
}

/* horizontal */
.padding-h--quarduple {
  padding-left: calc( var(--gutter) * 4 );
  padding-right: calc( var(--gutter) * 4 );
}

.padding-h--tripple {
  padding-left: calc( var(--gutter) * 3 );
  padding-right: calc( var(--gutter) * 3 );
}

.padding-h--double {
  padding-left: calc( var(--gutter) * 2 );
  padding-right: calc( var(--gutter) * 2 );
}

.padding-h--and-half {
  padding-left: calc( var(--gutter) * 1.5 );
  padding-right: calc( var(--gutter) * 1.5 );
}

.padding-h--and-third {
  padding-left: calc( var(--gutter) * 1.333 );
  padding-right: calc( var(--gutter) * 1.333 );
}

.padding-h--and-quarter {
  padding-left: calc( var(--gutter) * 1.25 );
  padding-right: calc( var(--gutter) * 1.25 );
}

.padding-h {
  padding-left: calc( var(--gutter) * 1 );
  padding-right: calc( var(--gutter) * 1 );
}

.padding-h--half {
  padding-left: calc( var(--gutter) * .5 );
  padding-right: calc( var(--gutter) * .5 );
}

.padding-h--third {
  padding-left: calc( var(--gutter) * .333 );
  padding-right: calc( var(--gutter) * .333 );
}

.padding-h--quarter {
  padding-left: calc( var(--gutter) * .25 );
  padding-right: calc( var(--gutter) * .25 );
}

/* vertical */
.padding-v--quarduple {
  padding-top: calc( var(--gutter) * 4 );
  padding-bottom: calc( var(--gutter) * 4 );
}

.padding-v--tripple {
  padding-top: calc( var(--gutter) * 3 );
  padding-bottom: calc( var(--gutter) * 3 );
}

.padding-v--double {
  padding-top: calc( var(--gutter) * 2 );
  padding-bottom: calc( var(--gutter) * 2 );
}

.padding-v--and-half {
  padding-top: calc( var(--gutter) * 1.5 );
  padding-bottom: calc( var(--gutter) * 1.5 );
}

.padding-v--and-third {
  padding-top: calc( var(--gutter) * 1.333 );
  padding-bottom: calc( var(--gutter) * 1.333 );
}

.padding-v--and-quarter {
  padding-top: calc( var(--gutter) * 1.25 );
  padding-bottom: calc( var(--gutter) * 1.25 );
}

.padding-v {
  padding-top: calc( var(--gutter) * 1 );
  padding-bottom: calc( var(--gutter) * 1 );
}

.padding-v--half {
  padding-top: calc( var(--gutter) * .5 );
  padding-bottom: calc( var(--gutter) * .5 );
}

.padding-v--third {
  padding-top: calc( var(--gutter) * .333 );
  padding-bottom: calc( var(--gutter) * .333 );
}

.padding-v--quarter {
  padding-top: calc( var(--gutter) * .25 );
  padding-bottom: calc( var(--gutter) * .25 );
}

/* top */
.padding-top--quarduple {
  padding-top: calc( var(--gutter) * 4 );
}

.padding-top--tripple {
  padding-top: calc( var(--gutter) * 3 );
}

.padding-top--double {
  padding-top: calc( var(--gutter) * 2 );
}

.padding-top--and-half {
  padding-top: calc( var(--gutter) * 1.5 );
}

.padding-top--and-third {
  padding-top: calc( var(--gutter) * 1.333 );
}

.padding-top--and-quarter {
  padding-top: calc( var(--gutter) * 1.25 );
}

.padding-top {
  padding-top: calc( var(--gutter) * 1 );
}

.padding-top--half {
  padding-top: calc( var(--gutter) * .5 );
}

.padding-top--third {
  padding-top: calc( var(--gutter) * .333 );
}

.padding-top--quarter {
  padding-top: calc( var(--gutter) * .25 );
}

/* bottom */
.padding-bottom--quarduple {
  padding-bottom: calc( var(--gutter) * 4 );
}

.padding-bottom--tripple {
  padding-bottom: calc( var(--gutter) * 3 );
}

.padding-bottom--double {
  padding-bottom: calc( var(--gutter) * 2 );
}

.padding-bottom--and-half {
  padding-bottom: calc( var(--gutter) * 1.5 );
}

.padding-bottom--and-third {
  padding-bottom: calc( var(--gutter) * 1.333 );
}

.padding-bottom--and-quarter {
  padding-bottom: calc( var(--gutter) * 1.25 );
}

.padding-bottom {
  padding-bottom: calc( var(--gutter) * 1 );
}

.padding-bottom--half {
  padding-bottom: calc( var(--gutter) * .5 );
}

.padding-bottom--third {
  padding-bottom: calc( var(--gutter) * .333 );
}

.padding-bottom--quarter {
  padding-bottom: calc( var(--gutter) * .25 );
}

/* left */
.padding-left--quarduple {
  padding-left: calc( var(--gutter) * 4 );
}

.padding-left--tripple {
  padding-left: calc( var(--gutter) * 3 );
}

.padding-left--double {
  padding-left: calc( var(--gutter) * 2 );
}

.padding-left--and-half {
  padding-left: calc( var(--gutter) * 1.5 );
}

.padding-left--and-third {
  padding-left: calc( var(--gutter) * 1.333 );
}

.padding-left--and-quarter {
  padding-left: calc( var(--gutter) * 1.25 );
}

.padding-left {
  padding-left: calc( var(--gutter) * 1 );
}

.padding-left--half {
  padding-left: calc( var(--gutter) * .5 );
}

.padding-left--third {
  padding-left: calc( var(--gutter) * .333 );
}

.padding-left--quarter {
  padding-left: calc( var(--gutter) * .25 );
}

/* right */
.padding-right--quarduple {
  padding-right: calc( var(--gutter) * 4 );
}

.padding-right--tripple {
  padding-right: calc( var(--gutter) * 3 );
}

.padding-right--double {
  padding-right: calc( var(--gutter) * 2 );
}

.padding-right--and-half {
  padding-right: calc( var(--gutter) * 1.5 );
}

.padding-right--and-third {
  padding-right: calc( var(--gutter) * 1.333 );
}

.padding-right--and-quarter {
  padding-right: calc( var(--gutter) * 1.25 );
}

.padding-right {
  padding-right: calc( var(--gutter) * 1 );
}

.padding-right--half {
  padding-right: calc( var(--gutter) * .5 );
}

.padding-right--third {
  padding-right: calc( var(--gutter) * .333 );
}

.padding-right--quarter {
  padding-right: calc( var(--gutter) * .25 );
}



/*------------------------------------*\
  margin
\*------------------------------------*/
.margin--quarduple {
  margin: calc( var(--gutter) * 4 );
}

.margin--tripple {
  margin: calc( var(--gutter) * 3 );
}

.margin--double {
  margin: calc( var(--gutter) * 2 );
}

.margin--and-half {
  margin: calc( var(--gutter) * 1.5 );
}

.margin--and-third {
  margin: calc( var(--gutter) * 1.333 );
}

.margin--and-quarter {
  margin: calc( var(--gutter) * 1.25 );
}

.margin {
  margin: calc( var(--gutter) * 1 );
}

.margin--half {
  margin: calc( var(--gutter) * .5 );
}

.margin--third {
  margin: calc( var(--gutter) * .333 );
}

.margin--quarter {
  margin: calc( var(--gutter) * .25 );
}

/* top */
.margin-top--quarduple {
  margin-top: calc( var(--gutter) * 4 );
}

.margin-top--tripple {
  margin-top: calc( var(--gutter) * 3 );
}

.margin-top--double {
  margin-top: calc( var(--gutter) * 2 );
}

.margin-top--and-half {
  margin-top: calc( var(--gutter) * 1.5 );
}

.margin-top--and-third {
  margin-top: calc( var(--gutter) * 1.333 );
}

.margin-top--and-quarter {
  margin-top: calc( var(--gutter) * 1.25 );
}

.margin-top {
  margin-top: calc( var(--gutter) * 1 );
}

.margin-top--half {
  margin-top: calc( var(--gutter) * .5 );
}

.margin-top--third {
  margin-top: calc( var(--gutter) * .333 );
}

.margin-top--quarter {
  margin-top: calc( var(--gutter) * .25 );
}

/* bottom */
.margin-bottom--quarduple {
  margin-bottom: calc( var(--gutter) * 4 );
}

.margin-bottom--tripple {
  margin-bottom: calc( var(--gutter) * 3 );
}

.margin-bottom--double {
  margin-bottom: calc( var(--gutter) * 2 );
}

.margin-bottom--and-half {
  margin-bottom: calc( var(--gutter) * 1.5 );
}

.margin-bottom--and-third {
  margin-bottom: calc( var(--gutter) * 1.333 );
}

.margin-bottom--and-quarter {
  margin-bottom: calc( var(--gutter) * 1.25 );
}

.margin-bottom {
  margin-bottom: calc( var(--gutter) * 1 );
}

.margin-bottom--half {
  margin-bottom: calc( var(--gutter) * .5 );
}

.margin-bottom--third {
  margin-bottom: calc( var(--gutter) * .333 );
}

.margin-bottom--quarter {
  margin-bottom: calc( var(--gutter) * .25 );
}

/* left */
.margin-left--0 {
  margin-left: 0
}

.margin-left--quarduple {
  margin-left: calc( var(--gutter) * 4 );
}

.margin-left--tripple {
  margin-left: calc( var(--gutter) * 3 );
}

.margin-left--double {
  margin-left: calc( var(--gutter) * 2 );
}

.margin-left--and-half {
  margin-left: calc( var(--gutter) * 1.5 );
}

.margin-left--and-third {
  margin-left: calc( var(--gutter) * 1.333 );
}

.margin-left--and-quarter {
  margin-left: calc( var(--gutter) * 1.25 );
}

.margin-left {
  margin-left: calc( var(--gutter) * 1 );
}

.margin-left--half {
  margin-left: calc( var(--gutter) * .5 );
}

.margin-left--third {
  margin-left: calc( var(--gutter) * .333 );
}

.margin-left--quarter {
  margin-left: calc( var(--gutter) * .25 );
}

/* right */
.margin-right--0 {
  margin-right: 0;
}

.margin-right--quarduple {
  margin-right: calc( var(--gutter) * 4 );
}

.margin-right--tripple {
  margin-right: calc( var(--gutter) * 3 );
}

.margin-right--double {
  margin-right: calc( var(--gutter) * 2 );
}

.margin-right--and-half {
  margin-right: calc( var(--gutter) * 1.5 );
}

.margin-right--and-third {
  margin-right: calc( var(--gutter) * 1.333 );
}

.margin-right--and-quarter {
  margin-right: calc( var(--gutter) * 1.25 );
}

.margin-right {
  margin-right: calc( var(--gutter) * 1 );
}

.margin-right--half {
  margin-right: calc( var(--gutter) * .5 );
}

.margin-right--third {
  margin-right: calc( var(--gutter) * .333 );
}

.margin-right--quarter {
  margin-right: calc( var(--gutter) * .25 );
}


/*------------------------------------*\
  HEADER LAYOUT
\*------------------------------------*/
.header--absolute {
  position: absolute;
}

.header--fixed {
  position: fixed;
  position: sticky;
}


/*------------------------------------*\
  FONT SIZE 
\*------------------------------------*/
.font-size--3 {
  font-size: 3em;
}

.font-size--2 {
  font-size: 2em;
}



/*------------------------------------*\
    $HEIGHT
\*------------------------------------*/

.height-viewport {
    height: 100%;
    min-height: 100vh;
}

.height--100 {
    height: 100%;
}

.minheight--100 {
    min-height: 100%;
}



/*------------------------------------*\
  FLEXBOX
\*------------------------------------*/

.flex {
    display: flex;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex--vertical {
    flex-direction: column;
}

/* Justify */
.flex-justify-start {
    justify-content: flex-start;
}

.flex-justify-center {
    justify-content: center;
}

.flex-justify-end {
    justify-content: flex-end;
}

.flex-justify-between {
    justify-content: space-between;
}

.flex-justify-around {
    justify-content: space-around;
}

/* Align */
.flex-align-start {
    align-items: flex-start;
}

.flex-align-center {
    align-items: center;
}

.flex-align-end {
    align-items: flex-end;
}

.flex-align-between {
    align-items: space-between;
}

.flex-align-around {
    align-items: space-around;
}

/*------------------------------------*\
    $Pre defined flex box
\*------------------------------------*/

.flex--top {
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
}

.flex--middle {
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}

.flex--bottom {
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-end;
}



.flex--left {
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
}

.flex--center {
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
}

.flex--right {
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-end;
}



.flex--top-left {
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.flex--top-center {
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.flex--top-right {
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
}



.flex--middle-left {
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.flex--middle-center {
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flex--middle-right {
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}



.flex--bottom-left {
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}

.flex--bottom-center {
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.flex--bottom-right {
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}


/*------------------------------------*\
    $POSITION
\*------------------------------------*/

.relative {
    position: relative;
}

.absolute {
    position: absolute;
}
