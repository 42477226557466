/*------------------------------------*\
    #HERO
\*------------------------------------*/

@keyframes bgcolor {
	0% {
		transform: translateX(0%);
	}
  100%	{
    transform: translateX(-83.3333%);
  }
}

@keyframes bounce {
  from {
    transform: translateY(-10%);
  }
  to {
    transform: translateY(10%);
  }
}

@keyframes vibrate {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0) scale(0.8);
  }
  to {
    transform: rotate(360deg) scale(0.8);
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.8);
  }

  10% {
    transform: scale(1);
  }

  15% {
    transform: scale(0.8);
  }

  30% {
    transform: scale(0.8);
  }

  40% {
    transform: scale(1);
  }

  45% {
    transform: scale(0.8);
  }

  100% {
    transform: scale(0.8);
  }
}

@keyframes rock {
  0% {
    transform: rotate(0) scale(0.8);
  }

  50% {
    transform: rotate(10deg) scale(0.8);
  }

  100% {
    transform: rotate(0) scale(0.8);
  }
}


.main {
  overflow: hidden;
}


.block--hero {
  min-height: 100vh;
}

/* media */
.block--hero .block__media {
  position: relative;
  overflow: hidden;
}

.block--hero .block__bg-image img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.block--hero .block__bg-image:after {
  content: '';
  opacity: .5;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 1000%;
  pointer-events: none;

  background-image: linear-gradient(to right, 
    var(--gradient-1a) calc(100%/12*0), 
    var(--gradient-1b) calc(100%/12*1),
    var(--gradient-2a) calc(100%/12*2), 
    var(--gradient-2b) calc(100%/12*3),
    var(--gradient-3a) calc(100%/12*4), 
    var(--gradient-3b) calc(100%/12*5),
    var(--gradient-4a) calc(100%/12*6), 
    var(--gradient-4b) calc(100%/12*7),
    var(--gradient-5a) calc(100%/12*8), 
    var(--gradient-5b) calc(100%/12*9),
    var(--gradient-1a) calc(100%/12*10), 
    var(--gradient-1b) calc(100%/12*11),
    var(--gradient-2a) calc(100%/12*12)
  );

  will-change: transform;
  animation-name: bgcolor;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

}


.block--hero .block__image {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;

  padding-right: var(--gutter);
}

@media all and (min-width: 1024px) {
  .block--hero .block__image {
    align-items: center;
  }
}

.block--hero .block__image a {
  position: relative;
  display: block;

  will-change: transform;
  animation-name: bounce;
  animation-duration: 2s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.block--hero .block__image a:hover {
  animation-play-state: paused;
}

.block--hero .block__image img{
  /* transition: transform .3s ease-in-out; */
  transition: transform 3s ease-in-out;
}

.block--hero .block__image a:hover img {
  transform: scale(1.5);
}

.block--hero .block__image a:focus img {
  transition: transform .3s ease-in-out;
  transform: translate(50%, -120%);
}

.block--hero .block__image img {
  object-fit: contain;
  max-height: 90vh;
}

.block--hero .block__image-content {
  position: absolute;
  top: 50%;
  left: 50%;

  transform: rotate(-45deg) translate(0%, -210%);
  text-shadow: -8px 8px 0 var(--black);

  transition: opacity .15s ease-in-out;
  transition-delay: 3s;
}

.block--hero .block__image a:focus .block__image-content {
  opacity: 0;
  transition: opacity .15s ease-in-out;
  
}

/* content */
.block--hero  .block__content {
  pointer-events: none;
  padding-top: 180px;
}

.block--hero .block__heading {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  height: 100%;
  margin-right: var(--gutter);
}



.block--hero .block__title {
  padding: calc(var(--gutter) * .25) calc(var(--gutter) * .5); 
  letter-spacing: .2em;

  display: flex;
  align-items: center;
}

.block--hero .block__title img {
  margin: calc(var(--gutter) * .5);
  margin-left: 0;
}

.block--hero .block__subtitle {
  padding: calc(var(--gutter) * .25) calc(var(--gutter) * .5); 
  letter-spacing: .2em;
}

.logo-2 {
  width: 100%;
  max-width: 700px;
}



/*------------------------------------*\
    #FOOTER
\*------------------------------------*/

.header {
  position: absolute;
  z-index: 10000;
  margin: var(--gutter);
}

.header__logo {
    padding-top: calc( var(--gutter) * .5 );
    padding-bottom: calc( var(--gutter) * .5 );
}

.header__logo a {
    display: block;
    color: inherit;
    text-decoration: none;
}

.body .header__content {
  display: block;
}

.header__nav {
  background-color: #FFDD0E;
  text-decoration: none;
  display: block !important;
  margin-top: calc(var(--gutter) * .25);
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  padding-top: .2em;
  padding-bottom: .2em;
  transition: all .3s ease-in-out;
}

.header__nav:hover {
  background-color: var(--color-2);
  color: white !important;
}

/*------------------------------------*\
    #FOOTER
\*------------------------------------*/
.footer {
    background-color: var(--black);
    color: var(--white);
}



.footer__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: baseline;

    padding-top: calc( var(--gutter) * .25);
    padding-bottom: calc( var(--gutter) * .25);
}

.footer a {
    color: inherit;
    text-decoration: none;
}


.btn {
  background-color: transparent;
  transition: all .1s ease-in-out;
  background-image: linear-gradient(to right, transparent, transparent, var(--gradient-2a), var(--gradient-2b));
  background-size: 300% 100%;
}

.btn:hover,
.btn:active,
.btn:focus {
  background-position: 100% 100%;
  background-color: transparent;
  color: var(--white) !important;
}




/*------------------------------------*\
    #Block Fade Down
\*------------------------------------*/

.block__icon--round {
  display: inline-block;
  position: relative;
  padding: var(--gutter);

  color: var(--white);
}

.block__icon--round i {
  position: relative;
}

.block__icon--round:before {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 100%;
  height: 0;
  background-color: var(--black);
  border-radius: 50%;
}




.block__overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background-color: var(--black);
  opacity: .5;

  pointer-events: none;
}

/*------------------------------------*\
    #block
\*------------------------------------*/

.block--custom {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr) ) ;

}

/*.block--custom .block__item {
    width: 100%;
    max-width: 400px;
}*/



.block--style-1 {
    position: relative;
    overflow: hidden;
}

.block--style-1 .block__image:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(transparent, var(--white));
    opacity: 0;

    transition: opacity .3s ease-in-out;
}

.block--style-1:hover .block__image:after,
.block--style-1:active .block__image:after,
.block--style-1:focus .block__image:after {
    opacity: .5;
}

.block--style-1 .block__content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: var(--gutter);
    opacity: 0;
    transform: translateY(3%);

    transition: all .3s ease-in-out;
}

.block--style-1:hover .block__content,
.block--style-1:active .block__content,
.block--style-1:focus .block__content {
    opacity: 1;
    transform: translateY(0);
}

:root {
  --vertical: 25%;
  --verticalscroll: calc( var(--vertical) / 2 );
  --verticalscroll-upwards: calc( var(--vertical) );
}

[data-scroll] {
  transition: all .3s ease-in-out;
  will-change: transform;
}

[data-scroll="in"] {
  opacity: 1;
}

[data-scroll-dir-y="1"] [data-scroll="out"] {
  opacity: calc( .5 - .5 * var(--intersect-y) );
  transform: translateY( calc( var(--verticalscroll) + var(--verticalscroll)  * var(--intersect-y))  );
}
/*
[data-scroll-dir-y="-1"] [data-scroll="out"] {
  opacity: calc( .5 + .5 * var(--intersect-y) );
  transform: translateY( calc( -1 * var(--verticalscroll) + var(--verticalscroll)  * var(--intersect-y))  );
}*/


.countdown ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.countdown li {
  display: inline-block;
  font-size: 1.5em;
  list-style-type: none;
  padding: 1em;
  text-transform: uppercase;
}

.countdown li span {
  display: block;
  font-size: 4.5rem;
}


.admin-input {
  position: relative;
  display: none;
}

[mv-mode~="edit"] .admin-input {
  display: block;
}

.grid--auto {
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.grid__item--square {
  position: relative;
  height: 0;
  padding-top: 100%;
  overflow: hidden;
  background-color: rgba(255,255,255,1);
}

.grid__item--square img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: var(--gutter)
}

[mv-mode~="edit"] .grid__item--square img {
  /* position: relative;
  transform: translate(0); */
}

[mv-mode~="edit"] .grid__item--square {
  /* height: initial;
  padding-top: initial; */
  overflow: initial;
}

[mv-mode~="edit"] .grid__item--square .admin-input {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9000;
}

.grid--sponsor {
  grid-template-columns: repeat(6, 1fr);
}

@media all and (min-width: 768px) {
  .grid--sponsor {
    grid-template-columns: repeat(12, 1fr);
  }
}


.grid__item--1 {
  grid-column: span 6;
  grid-row: span 6;
}

.grid__item--2 {
  grid-column:  span 5;
  grid-row: span 5;
}

.grid__item--3 {
  grid-column:  span 4;
  grid-row: span 4;
}

.grid__item--4 {
  grid-column:  span 3;
  grid-row: span 3;
}

.grid__item--5 {
  grid-column:  span 2;
  grid-row: span 2;
}



/* Icon Farbe */
@supports(-webkit-background-clip: text) {
  .fa, .fas, .fab {
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient( to right, var(--gradient-5a), var(--gradient-5b) );
  }

  .feature:nth-child(1) .fa,
  .feature:nth-child(1) .fas,
  .feature:nth-child(1) .fab {
    background-image: linear-gradient( to right, var(--gradient-1a), var(--gradient-1b) );
  }

  .feature:nth-child(2) .fa,
  .feature:nth-child(2) .fas,
  .feature:nth-child(2) .fab {
    background-image: linear-gradient( to right, var(--gradient-2a), var(--gradient-2b) );
  }

  .feature:nth-child(3) .fa,
  .feature:nth-child(3) .fas,
  .feature:nth-child(3) .fab {
    background-image: linear-gradient( to right, var(--gradient-4a), var(--gradient-4b) );
  }
}

.block--what .block__image {
  will-change: transform;
  animation-name: rotate;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}


.animate--pulse {
  will-change: transform;
  animation-name: pulse;
  animation-duration: 1.1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.animate--rock {
  will-change: transform;
  animation-name: rock;
  animation-duration: .5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}


.gradient-border {
  --borderWidth: calc(var(--gutter) * .5);
  background: var(--white);
  position: relative;
  border-radius: var(--borderWidth);
}

.gradient-border:after {
  content: '';
  position: absolute;
  top: calc(-1 * var(--borderWidth));
  left: calc(-1 * var(--borderWidth));
  height: calc(100% + var(--borderWidth) * 2);
  width: calc(100% + var(--borderWidth) * 2);
  background-image: linear-gradient(60deg, 
    var(--gradient-1a) calc(100%/12*0), 
    var(--gradient-1b) calc(100%/12*1),
    var(--gradient-2a) calc(100%/12*2), 
    var(--gradient-2b) calc(100%/12*3),
    var(--gradient-3a) calc(100%/12*4), 
    var(--gradient-3b) calc(100%/12*5),
    var(--gradient-4a) calc(100%/12*6), 
    var(--gradient-4b) calc(100%/12*7),
    var(--gradient-5a) calc(100%/12*8), 
    var(--gradient-5b) calc(100%/12*9),
    var(--gradient-1a) calc(100%/12*10), 
    var(--gradient-1b) calc(100%/12*11),
    var(--gradient-2a) calc(100%/12*12)
  );
  border-radius: calc(2 * var(--borderWidth));
  z-index: -1;

  animation-name: animatedgradient;
  animation-duration: 60s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  background-size: 1000% 100%;
}


@keyframes animatedgradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}


.block--sponsor {
  background-color: var(--white);
  padding: calc(var(--gutter) * .25);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media all and (min-width: 768px) {
  .block--sponsor {
    padding: var(--gutter);
  }
}