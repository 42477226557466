/**
 * CONTENTS
 *
 * SETTINGS
 * Global...............Globally-available variables and config.
 *
 * TOOLS
 * Mixins...............Useful mixins.
 *
 * GENERIC
 * Normalize.css........A level playing field.
 * Box-sizing...........Better default `box-sizing`.
 *
 * BASE
 * Headings.............H1–H6 styles.
 *
 * OBJECTS
 * Wrappers.............Wrapping and constraining elements.
 *
 * COMPONENTS
 * Page-head............The main page header.
 * Page-foot............The main page footer.
 * Buttons..............Button elements.
 *
 * TRUMPS
 * Text.................Text helpers.
 */


/*------------------------------------*\
    #SETTINGS
\*------------------------------------*/
@import url('theme/variables.css');





/*------------------------------------*\
    #GENERIC
\*------------------------------------*/
@import url('general/sanitize.css');





/*------------------------------------*\
    #BASE
\*------------------------------------*/
@import url('general/global-phucstrap.css');

@import url('theme/global-theme.css');
@import url('general/button.css');
/* @import url('theme/form.css'); */




/*------------------------------------*\
    #OBJECTS
\*------------------------------------*/
@import url('general/layout.css');
/* @import url('theme/sections.css'); */






/*------------------------------------*\
    #COMPONENTS
\*------------------------------------*/
/* @import url('theme/header.css'); */
@import url('general/burger.css');
/* @import url('theme/footer.css'); */
/* @import url('theme/nav.css'); */





/*------------------------------------*\
    #TRUMPS
\*------------------------------------*/
@import url('theme/theme.css');
@import url('theme/custom.css');
