/*------------------------------------*\
  #GENERAL
\*------------------------------------*/

.body {
  font-family: var(--font-family);
  font-size: var(--font-size-base);
  line-height: 1.5;

  color: var(--black);
  background-color: var(--white);
}

.body a {
  color: var(--color-primary);
}

.body a:hover,
.body a:active,
.body a:focus {
  color: var(--color-primary);
}

.body  ul, 
.body  ol, 
.body  dl {
  padding-left: 1em;
}

.body li {
  margin: 0;
}

.body  blockquote {
  margin-left: 0;
  margin-right: 0;
}

p {
  margin: 0;
}

.description > * {
  margin-top: var(--gutter-p);
}

.description > *:first-child {
  margin-top: 0;
}
